/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    ------------------------------------------------------------------------
    Date         Author   Comment
    ------------------------------------------------------------------------
    2024-07-03	  Khyati   This is upload video in offline mode reducer file
*/

import {
  VIDEO_UPLOAD_REQUEST,
  VIDEO_UPLOAD_SUCCESS,
  VIDEO_UPLOAD_FAILURE,
  VIDEO_UPLOAD_RESET,
} from "../../actions/types";

const initialState = {
  data: {},
  loading: false,
  error: null,
};

const VideoUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIDEO_UPLOAD_REQUEST:
      return { ...state, loading: true, error: null };
    case VIDEO_UPLOAD_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case VIDEO_UPLOAD_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case VIDEO_UPLOAD_RESET:
      return initialState;
    default:
      return state;
  }
};

export default VideoUploadReducer;
