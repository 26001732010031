/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    -----------------------------------------------------------------
    Date         Author   Comment
    -----------------------------------------------------------------
    2024-06-10	  Khyati   This is branch list user wise reducer file
*/

import {
  GET_USER_BRANCH_REQUEST,
  GET_USER_BRANCH_SUCCESS,
  GET_USER_BRANCH_FAILURE,
  GET_USER_BRANCH_RESET,
} from "../../actions/types";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const UserBranchReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_BRANCH_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_USER_BRANCH_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case GET_USER_BRANCH_FAILURE:
      return { data: [], loading: false, error: null };
    case GET_USER_BRANCH_RESET:
      return initialState;
    default:
      return state;
  }
};

export default UserBranchReducer;
