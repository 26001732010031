/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    ----------------------------------------------------
    Date         Author   Comment
    ----------------------------------------------------
    2024-06-10	  Khyati   This is send OTP reducer file
*/

import {
  SEND_OTP_REQURST,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAILURE,
  SEND_OTP_RESET,
} from "../../actions/types";

const initialState = {
  data: {},
  loading: false,
  error: null,
};

const SendOTPReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_OTP_REQURST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SEND_OTP_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case SEND_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SEND_OTP_RESET:
      return initialState;
    default:
      return state;
  }
};

export default SendOTPReducer;
