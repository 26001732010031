/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    -----------------------------------------------------------------------
    Date         Author   Comment
    -----------------------------------------------------------------------
    2024-06-28	  Khyati   This is reducer file exporting score card in CSV
*/

import {
  EXPORT_SCORE_CARD_REQUEST,
  EXPORT_SCORE_CARD_SUCCESS,
  EXPORT_SCORE_CARD_FAILURE,
  EXPORT_SCORE_CARD_RESET,
} from "../../actions/types";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const ExportScoreCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case EXPORT_SCORE_CARD_REQUEST:
      return { ...state, loading: true, error: null };
    case EXPORT_SCORE_CARD_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case EXPORT_SCORE_CARD_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case EXPORT_SCORE_CARD_RESET:
      return initialState;
    default:
      return state;
  }
};

export default ExportScoreCardReducer;
