/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    ---------------------------------------------------------------
    Date         Author   Comment
    ---------------------------------------------------------------
    2024-06-10	  Khyati   This is individual Video PD reducer file
*/

import {
  GET_INDIVIDUAL_VIDEO_PD_REQUEST,
  GET_INDIVIDUAL_VIDEO_PD_SUCCESS,
  GET_INDIVIDUAL_VIDEO_PD_FAILURE,
  RESET_INDIVIDUAL_VIDEO_PD,
} from "../../actions/types";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const GetIndividualVideoPD = (state = initialState, action) => {
  switch (action.type) {
    case GET_INDIVIDUAL_VIDEO_PD_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_INDIVIDUAL_VIDEO_PD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case GET_INDIVIDUAL_VIDEO_PD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_INDIVIDUAL_VIDEO_PD:
      return initialState;
    default:
      return state;
  }
};

export default GetIndividualVideoPD;
