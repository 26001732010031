/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    -------------------------------------------------------
    Date         Author   Comment
    -------------------------------------------------------
    2024-06-10	  Khyati   This is user status reducer file
*/

import {
  ACTIVE_INACTIVE_USER_REQUEST,
  ACTIVE_INACTIVE_USER_SUCCESS,
  ACTIVE_INACTIVE_USER_FAILURE,
  RESET_ACTIVE_INACTIVE_USER,
} from "../../actions/types";

const initialState = {
  data: {},
  loading: false,
  error: null,
};

const UserStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVE_INACTIVE_USER_REQUEST:
      return { ...state, loading: true, error: null };
    case ACTIVE_INACTIVE_USER_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case ACTIVE_INACTIVE_USER_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case RESET_ACTIVE_INACTIVE_USER:
      return { data: {}, loading: false, error: null };
    default:
      return state;
  }
};

export default UserStatusReducer;
