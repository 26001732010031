/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    --------------------------------------------------------------------
    Date         Author   Comment
    --------------------------------------------------------------------
    2024-06-28	  Khyati   This is reducer file exporting history in CSV
*/

import {
  EXPORT_HISTORY_REQUEST,
  EXPORT_HISTORY_SUCCESS,
  EXPORT_HISTORY_FAILURE,
  EXPORT_HISTORY_RESET,
} from "../../actions/types";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const ExportHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case EXPORT_HISTORY_REQUEST:
      return { ...state, loading: true, error: null };
    case EXPORT_HISTORY_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case EXPORT_HISTORY_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case EXPORT_HISTORY_RESET:
      return initialState;
    default:
      return state;
  }
};

export default ExportHistoryReducer;
