/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    ------------------------------------------------------------------
    Date         Author   Comment
    ------------------------------------------------------------------
    2024-06-10	  Khyati   This is get download video URL reducer file
*/

import {
  DOWNLOAD_VIDEO_REQUEST,
  DOWNLOAD_VIDEO_SUCCESS,
  DOWNLOAD_VIDEO_FAILURE,
  DOWNLOAD_VIDEO_RESET,
} from "../../actions/types";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const GetDownloadVideoUrlReducer = (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOAD_VIDEO_REQUEST:
      return { ...state, loading: true, error: null };
    case DOWNLOAD_VIDEO_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case DOWNLOAD_VIDEO_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case DOWNLOAD_VIDEO_RESET:
      return initialState;
    default:
      return state;
  }
};

export default GetDownloadVideoUrlReducer;
