/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    -------------------------------------------------------
    Date         Author   Comment
    -------------------------------------------------------
    2024-06-10	  Khyati   This is branch list reducer file
*/

import {
  BRANCH_LIST_REQUEST,
  BRANCH_LIST_SUCCESS,
  BRANCH_LIST_FAILURE,
} from "../../actions/types";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const BranchListReducer = (state = initialState, action) => {
  switch (action.type) {
    case BRANCH_LIST_REQUEST:
      return { ...state, loading: true, error: null };
    case BRANCH_LIST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case BRANCH_LIST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default BranchListReducer;
