/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    -----------------------------------------------------------
    Date         Author   Comment
    -----------------------------------------------------------
    2024-06-10	  Khyati   This is forgot password reducer file
*/

import {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_RESET,
} from "../../actions/types";

const initialState = {
  data: {},
  loading: false,
  error: null,
};

const ForgotPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FORGOT_PASSWORD_RESET:
      return initialState;
    default:
      return state;
  }
};

export default ForgotPasswordReducer;
