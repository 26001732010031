/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    ------------------------------------------------------
    Date         Author   Comment
    ------------------------------------------------------
    2024-06-10	  Khyati   This is statistics reducer file
*/

import {
  LOAN_COUNT_LIST_REQUEST,
  LOAN_COUNT_LIST_SUCCESS,
  LOAN_COUNT_LIST_FAILURE,
} from "../../actions/types";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const LoanCountListReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAN_COUNT_LIST_REQUEST:
      return { ...state, loading: true, error: null };
    case LOAN_COUNT_LIST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case LOAN_COUNT_LIST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default LoanCountListReducer;
