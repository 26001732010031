/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    -----------------------------------------------------
    Date         Author   Comment
    -----------------------------------------------------
    2024-06-10	  Khyati   This is user type reducer file
*/

import {
  GET_USER_TYPE_REQUEST,
  GET_USER_TYPE_SUCCESS,
  GET_USER_TYPE_FAILURE,
} from "../../actions/types";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const GetUserType = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_TYPE_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_USER_TYPE_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case GET_USER_TYPE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default GetUserType;
