/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    -----------------------------------------------------
    Date         Author   Comment
    -----------------------------------------------------
    2024-06-10	  Khyati   This is index file of reducers
*/

import { combineReducers } from "redux";
// authentication
import authReducer from "./auth/AuthReducer";
import sendOTP from "./auth/SendOTPReducer";
import forgotPassword from "./auth/ForgotPasswordReducer";
import verifyForgotPassword from "./auth/VerifyForgotPassword";
import resetPasswordReducer from "./auth/ResetPassword";
// branch
import branchList from "./branch/BranchListReducer";
import userBranchList from "./branch/UserBranchReducer";
// dahsboard
import loanCountList from "./dashboard/LoanCountListReducer";
import videoPDList from "./dashboard/VideoPDListReducer";
import videoPDUserList from "./dashboard/VideoPDUserWiseReducer";
// role
import roleList from "./role/RoleListReducer";
// user
import getUser from "./user/UserDetailReducer";
import userData from "./user/UserDataReducer";
import addUser from "./user/AddUserReducer";
import editUser from "./user/EditUserReducer";
import userStatus from "./user/UserStatusReducer";
import getReportingUser from "./user/GetReportingUser";
import getUserType from "./user/GetUserType";
// video PD
import generateVidoPD from "./videoPD/GenerateVideoPd";
import generateIndividualVideoPd from "./videoPD/GetIndividualVideoPD";
import userTypeAgainstLoanList from "./videoPD/UserTypeAganistLoanReducer";
import videoRecordingStartReducer from "./videoPD/VideoRecordingStartReducer";
import videoUploadReducer from "./videoPD/VideoUploadReducer";
// history
import exportHistoryReducer from "./history/ExportHistoryReducer";
import GetDownloadVideoUrlReducer from "./history/GetDownloadVideoUrlReducer";
import videoDownload from "./history/VideoDownloadReducer";
// score card
import exportScoreCardReducer from "./scoreCard/ExportScoreCardReducer";

const rootReducer = combineReducers({
  // authentication
  auth: authReducer,
  sendOTP: sendOTP,
  forgotPassword: forgotPassword,
  verifyForgotPassword: verifyForgotPassword,
  resetPassword: resetPasswordReducer,
  // branch
  branchList: branchList,
  userBranchList: userBranchList,
  // dahsboard
  loanCountList: loanCountList,
  videoPDList: videoPDList,
  videoPDUserList: videoPDUserList,
  // role
  roleList: roleList,
  // user
  getUser: getUser,
  userData: userData,
  addUserData: addUser,
  editUser: editUser,
  userStatus: userStatus,
  getReportingUser: getReportingUser,
  getUserType: getUserType,
  // video PD
  generateVidoPD: generateVidoPD,
  generateIndividualVideoPd: generateIndividualVideoPd,
  userTypeAgainstLoanList: userTypeAgainstLoanList,
  videoRecordingStartReducer: videoRecordingStartReducer,
  videoUploadReducer: videoUploadReducer,
  // history
  exportHistoryReducer: exportHistoryReducer,
  GetDownloadVideoUrlReducer: GetDownloadVideoUrlReducer,
  videoDownload: videoDownload,
  // score card
  exportScoreCardReducer: exportScoreCardReducer,
});

export default rootReducer;
