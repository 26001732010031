/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    -----------------------------------------------------------
    Date         Author   Comment
    -----------------------------------------------------------
    2024-06-10	  Khyati   This is app/main/root page of project
*/

import React from "react";

import RoutesList from "./routing/PublicRouting";

import "./App.css";
import VideoRecorder from "./components/videoPD/VideoRecorder copy";

function App() {
  return <RoutesList />; // <VideoRecorder />;
}

export default App;
